

























































































































































import { Vue, Component } from "vue-property-decorator";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { changeCurrencytoNumeric } from "@/validator/globalvalidator";
import { Messages } from "@/models/enums/messages.enum";
import { settingsServices } from "@/services/settings.service";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  DataRelatedCode,
  ResponseListOfTaxInvoiceCode,
} from "@/models/interface/settings.interface";
import { ColumnTableCustom } from "@/models/interface/util.interfase";
import { debounce } from "@/helpers/debounce";
@Component
export default class TaxInvoiceCode extends Vue {
  formatCurrencytoNumber = changeCurrencytoNumeric;
  titlemodalOpen = "" as string;
  idParams = "" as string;
  modalOpen = false as boolean;
  disabledTaxInvoiceCode = false as boolean;
  switchRevisionCode = false as boolean;
  totalData = 0 as number;
  loaddingDataListTaxInvoicecode = false as boolean;
  dataListTaxInvoicecode = {} as ResponseListOfTaxInvoiceCode;
  listRelatedCode = [] as DataRelatedCode[];
  LoadingDatalistRelatedCode = false as boolean;
  labelCol = { span: 8 };
  wrapperCol = { span: 16 };
  limit = 10 as number;
  page = 0 as number;
  loadingTable = false as boolean;
  isFormSubmitted = false as boolean;
  form = this.$form.createForm(this, { name: "taxInvoiceCode" });
  formRules = {
    taxInvoiceCode: {
      label: "lbl_tax_invoice_code",
      name: "code",
      placeholder: "lbl_tax_invoice_code_placeholder",
      decorator: [
        "code",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    description: {
      label: "lbl_description",
      name: "description",
      placeholder: "lbl_description_placeholder",
      decorator: [
        "description",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    relatedCode: {
      label: "lbl_related_code",
      name: "relatedCode",
      placeholder: "lbl_related_code_placeholder",
      decorator: [
        "relatedCode",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    revisionCode: {
      label: "lbl_revision_code",
      name: "revisionCode",
      placeholder: "lbl_revision_code_placeholder",
      decorator: [
        "revisionCode",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
  };
  columnsTable = [
    {
      title: this.$t("lbl_tax_invoice_code"),
      dataIndex: "code",
      key: "code",
      width: 100,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_description"),
      dataIndex: "description",
      key: "description",
      width: 100,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_related_code"),
      dataIndex: "relatedCode",
      key: "relatedCode",
      width: 100,
      scopedSlots: { customRender: "isNull" },
    },
  ] as ColumnTableCustom[];
  created() {
    this.getRelatedCode();
    this.columnsTable.push({
      title: this.$root.$t("lbl_action").toString(),
      dataIndex: "operation",
      key: "operation",
      scopedSlots: { customRender: "operation" },
      button: ["update"],
      width: 120,
      align: "center",
      fixed: "right",
    });
  }
  mounted() {
    this.getListTaxCode(false);
  }
  changeRevision(e) {
    if (!e)
      this.form.setFieldsValue({
        relatedCode: null,
      });
  }
  reponseEditTable(response) {
    this.modalOpen = true;
    this.titlemodalOpen = "lbl_edit_tax_invoice_code";
    this.idParams = response.data.id;
    this.disabledTaxInvoiceCode = true;
    debounce(() => {
      this.form.setFieldsValue({
        code: response.data.code,
        description: response.data.description,
        relatedCode: response.data.relatedCode,
      });
      this.switchRevisionCode = response.data.revisionCode;
    }, 500);
  }
  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }
  getRelatedCode() {
    let params = {
      search: "revisionCode~false_AND_used~false",
      limit: 10,
      page: 0,
    } as RequestQueryParamsModel;
    this.LoadingDatalistRelatedCode = true;
    settingsServices
      .listOfTaxInvoiceCode(params)
      .then(res => {
        res.data.map(
          (item, index) => (
            (item.key = index), (item.name = item.code), (item.id = item.code)
          )
        );
        this.listRelatedCode = res.data;
      })
      .finally(() => (this.LoadingDatalistRelatedCode = false));
  }
  handleCancel(): void {
    this.modalOpen = false;
  }
  addRow(): void {
    this.modalOpen = true;
    this.titlemodalOpen = "lbl_create_tax_invoice_code";
    this.form.resetFields();
    this.idParams = "";
    this.disabledTaxInvoiceCode = false;
    this.switchRevisionCode = false;
  }
  getListTaxCode(page) {
    let params = {
      limit: this.limit,
      page: page ? this.page - 1 : 0,
    } as RequestQueryParamsModel;
    this.loaddingDataListTaxInvoicecode = true;
    settingsServices
      .listOfTaxInvoiceCode(params)
      .then(res => {
        res.data.map((item, index) => (item.key = index));
        this.dataListTaxInvoicecode = res;
      })
      .finally(() => (this.loaddingDataListTaxInvoicecode = false));
  }
  save(e): void {
    e.preventDefault();
    this.form.validateFields((err, value) => {
      if (!err) {
        value["revisionCode"] = this.switchRevisionCode;
        value["relatedCode"] = value.relatedCode ? value.relatedCode : "";
        if (!this.idParams) {
          settingsServices
            .createTaxInvoiceCode(value)
            .then(() => {
              this.getListTaxCode(false);
              this.getRelatedCode();
              this.modalOpen = false;
              this.$notification.success({
                description: Messages.CREATE_SUCCESS,
                message: "Success",
                duration: 30,
              });
            })
            .catch(error => {
              this.$message.error(Messages.CREATE_FAIL, 30);
              this.$notification.error({
                description: error.details,
                message: "Error",
                duration: 30,
              });
            });
        } else {
          settingsServices
            .updateTaxInvoiceCode(value, this.idParams)
            .then(() => {
              this.$notification.success({
                description: Messages.UPDATE_SUCCESS,
                message: "Success",
                duration: 30,
              });

              this.getListTaxCode(false);
              this.getRelatedCode();
              this.modalOpen = false;
            })
            .catch(error => {
              this.$notification.error({
                description: error.details,
                message: "Error",
                duration: 30,
              });

              this.$notification.error({
                description: Messages.UPDATE_FAIL,
                message: "Error",
                duration: 30,
              });
            });
        }
      }
    });
  }
  onSelectChange(selectedRowKeys) {
    this.selectedRowKeys = selectedRowKeys;
  }
  responsePageSizeChange(response: ResponsePagination): void {
    this.limit = response.size;
    this.page = 1;
    this.getListTaxCode(true);
  }
  responseCurrentPageChange(response: ResponsePagination): void {
    this.page = response.page;
    this.getListTaxCode(true);
  }
}
