var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-layout-content",
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-card",
                    { attrs: { title: _vm.$t("lbl_tax_invoice_code") } },
                    [
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "flex-end"
                              },
                              attrs: { span: 24 }
                            },
                            [
                              _vm.$can("create", "tax-invoice-code")
                                ? _c(
                                    "a-button",
                                    {
                                      staticStyle: { width: "180px" },
                                      attrs: { type: "primary" },
                                      on: { click: _vm.addRow }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("lbl_create_new")) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { staticClass: "mt-2", attrs: { span: 24 } },
                            [
                              _c("TableCustom", {
                                attrs: {
                                  idtable: "table1",
                                  dataSource: _vm.dataListTaxInvoicecode.data,
                                  columns: _vm.columnsTable,
                                  scroll: { x: "calc(500px + 0%)" },
                                  paginationcustom: true,
                                  defaultPagination: false,
                                  loading: _vm.loadingTable
                                },
                                on: { "on-edit": _vm.reponseEditTable }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "a-space",
                                {
                                  staticStyle: { height: "50px" },
                                  attrs: { align: "center" }
                                },
                                [
                                  _c("Pagination", {
                                    attrs: {
                                      total:
                                        _vm.dataListTaxInvoicecode
                                          .totalElements,
                                      pageSizeSet: _vm.limit,
                                      idPagination: "pagination1"
                                    },
                                    on: {
                                      "response-pagesize-change":
                                        _vm.responsePageSizeChange,
                                      "response-currentpage-change":
                                        _vm.responseCurrentPageChange
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              staticClass: "mt-3",
                              attrs: { span: 12, justify: "end" }
                            },
                            [
                              _c(
                                "a-tag",
                                {
                                  staticStyle: { "font-size": "13px" },
                                  attrs: { color: "grey" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("lbl_total_found")) +
                                      " : " +
                                      _vm._s(
                                        _vm.dataListTaxInvoicecode.totalElements
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: {
            centered: false,
            dialogstyle: { top: "20px" },
            maskClosable: false,
            width: 600,
            title: _vm.$t(_vm.titlemodalOpen),
            showmodal: _vm.modalOpen,
            idmodal: "modal1"
          }
        },
        [
          _c(
            "div",
            { attrs: { slot: "contentmodal" }, slot: "contentmodal" },
            [
              _c(
                "a-form",
                {
                  attrs: {
                    form: _vm.form,
                    "label-col": _vm.labelCol,
                    "wrapper-col": _vm.wrapperCol
                  }
                },
                [
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  _vm.formRules.taxInvoiceCode.label
                                )
                              }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value:
                                      _vm.formRules.taxInvoiceCode.decorator,
                                    expression:
                                      "formRules.taxInvoiceCode.decorator"
                                  }
                                ],
                                attrs: {
                                  name: _vm.formRules.taxInvoiceCode.name,
                                  placeholder: _vm.$t(
                                    _vm.formRules.taxInvoiceCode.placeholder
                                  ),
                                  autocomplete: "off",
                                  disabled: _vm.disabledTaxInvoiceCode
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRules.description.label)
                              }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.description.decorator,
                                    expression:
                                      "formRules.description.decorator"
                                  }
                                ],
                                attrs: {
                                  name: _vm.formRules.description.name,
                                  placeholder: _vm.$t(
                                    _vm.formRules.description.placeholder
                                  ),
                                  autocomplete: "off"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRules.revisionCode.label),
                                required: ""
                              }
                            },
                            [
                              _c("a-switch", {
                                attrs: {
                                  checkedChildren: _vm.$t("lbl_yes"),
                                  unCheckedChildren: _vm.$t("lbl_no"),
                                  name: _vm.formRules.revisionCode.name
                                },
                                on: { change: _vm.changeRevision },
                                model: {
                                  value: _vm.switchRevisionCode,
                                  callback: function($$v) {
                                    _vm.switchRevisionCode = $$v
                                  },
                                  expression: "switchRevisionCode"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRules.relatedCode.label)
                              }
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  directives: [
                                    {
                                      name: "decorator",
                                      rawName: "v-decorator",
                                      value:
                                        _vm.formRules.relatedCode.decorator,
                                      expression:
                                        "formRules.relatedCode.decorator"
                                    }
                                  ],
                                  attrs: {
                                    disabled: !_vm.switchRevisionCode
                                      ? true
                                      : false,
                                    name: _vm.formRules.relatedCode.name,
                                    placeholder: _vm.$t(
                                      _vm.formRules.relatedCode.placeholder
                                    ),
                                    showSearch: true,
                                    "option-filter-prop": "children",
                                    "filter-option": _vm.filterOption,
                                    loading: _vm.LoadingDatalistRelatedCode,
                                    allowClear: true
                                  },
                                  on: {
                                    search: function(value) {
                                      return _vm.getListContact(value)
                                    }
                                  }
                                },
                                _vm._l(_vm.listRelatedCode, function(
                                  data,
                                  index
                                ) {
                                  return _c(
                                    "a-select-option",
                                    { key: index, attrs: { value: data.id } },
                                    [
                                      _c(
                                        "a-tooltip",
                                        [
                                          _c("template", { slot: "title" }, [
                                            _vm._v(
                                              " " + _vm._s("" + data.name) + " "
                                            )
                                          ]),
                                          _vm._v(
                                            " " + _vm._s("" + data.name) + " "
                                          )
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footermodal" }, slot: "footermodal" },
            [
              _c(
                "a-button",
                {
                  key: "back",
                  attrs: { type: "danger" },
                  on: { click: _vm.handleCancel }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")]
              ),
              _vm.$can("create", "tax-invoice-code") ||
              _vm.$can("update", "tax-invoice-code")
                ? _c(
                    "a-button",
                    {
                      key: "submit",
                      attrs: {
                        type: "primary",
                        loading: _vm.isFormSubmitted,
                        "html-type": "submit"
                      },
                      on: {
                        click: function($event) {
                          return _vm.save($event)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
                  )
                : _vm._e()
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }